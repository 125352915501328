import { useEffect, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  TextField,
  Typography,
} from "@mui/material";
//---------------------------------------------
import ConfirmDelete from "Components/Axios/ConfirmDelete";
import FetchData from "Components/Axios/FetchData";
//---------------------------------------------
import AddSupply from "./AddSupply/AddSupply";
import EditSupply from "./EditSupply/EditSupply";
import SupplyDetails from "./SupplyDetails/SupplyDetails";

import ButtonAdd from "Components/ButtonAdd/ButtonAdd";
import axios from "axios";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import { baseUrl } from "utils/baseUrl";

interface Supply {
  id: string;
  description: string;
  quantity: number;
  price: number;
  minValue: number;
  images: any;
  // secure_url: any;
  category: string[];
  tag: string[];
  name: any;
  warehouse?: any;
}

interface WarehouseAccess {
  id: string;
  // ... other properties
}

interface User {
  accessWarehouses: WarehouseAccess[];
}

export default function Supplies() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: categoriesData, error: categoriesError } =
    FetchData("categories");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const { data: warehouseData, error: warehouseError } = FetchData("warehouse");
  const [endpoint, setEndpoint] = useState("supplies/user-supplies");
  const {
    data: filteredData,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    error: filteredError,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading: filteredLoading,
  } = FetchData(endpoint);

  const [supplies, setSupplies] = useState<Supply[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string | null>(null);
  const [searchText, setSearchText] = useState("");
  const [selectedSupply, setSelectedSupply] = useState<Supply | null>(null);
  const [viewDetailsOpen, setViewDetailsOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditSupply, setOpenEditSupply] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [categories, setCategories] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedWarehouse, setSelectedWarehouse] = useState("All");
  const [selectedTag, setSelectedTag] = useState("All");
  const [tags, setTags] = useState([]);
  const [pageSize, setPageSize] = useState(10); // Default number of items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [totalValue, setTotalValue] = useState(0);

  const encryptedId = localStorage.getItem("encryptedId");
  const userId = encryptedId
    ? CryptoJS.AES.decrypt(encryptedId, "secret key 1oknvgudcxas23").toString(
        CryptoJS.enc.Utf8
      )
    : null;

  const {
    data: userData,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    error: userError,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading: userLoading,
  } = FetchData(`users/${userId}`);

  useEffect(() => {
    axios.get(`${baseUrl}/tags`).then((response) => {
      setTags(response.data);
    });
  }, []);

  useEffect(() => {
    async function fetchWarehousesDetails() {
      if (userData && Array.isArray(userData.accessWarehouses)) {
        try {
          const warehouseDetailsPromises = userData.accessWarehouses.map(
            (warehouseObj: any) => {
              const warehouseId = warehouseObj.id;
              return axios.get(`${baseUrl}/warehouses/${warehouseId}`);
            }
          );

          const warehousesResponses = await Promise.all(
            warehouseDetailsPromises
          );
          const warehousesDetails = warehousesResponses.map(
            (response) => response.data.warehouse
          );

          setWarehouses(warehousesDetails);
        } catch (error: any) {
          console.error(
            "Error fetching warehouses details",
            error.response.data
          );
        }
      } else {
        console.error(
          "userData.accessWarehouses is not an array or is invalid:",
          userData?.accessWarehouses
        );
      }
    }

    if (userData) {
      fetchWarehousesDetails();
    }
  }, [userData]);

  useEffect(() => {
    let query = "";
    if (selectedCategory !== "All") {
      const categoryId = categories.find(
        (c) => c.name === selectedCategory
      )?.id;
      query += `categoryId=${categoryId}`;
    }
    if (selectedWarehouse !== "All") {
      const warehouseId = warehouses.find(
        (w) => w.name === selectedWarehouse
      )?.id;
      query += (query ? "&" : "") + `warehouseId=${warehouseId}`;
    }
    if (selectedTag !== "All") {
      const tagId = tags.find((t) => t.name === selectedTag)?.id;
      query += (query ? "&" : "") + `tagId=${tagId}`;
    }
    setEndpoint(`supplies/user-supplies?${query}`);
  }, [
    selectedCategory,
    selectedWarehouse,
    selectedTag,
    categories,
    warehouses,
    tags,
  ]);

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleOpenEditSupply = async (id: any) => {
    try {
      const response = await axios.get(`${baseUrl}/supplies/${id}`);
      const selectedEquipment = response.data.supply;
      setSelectedSupply(selectedEquipment);
      setOpenEditSupply(true);
    } catch (error) {
      console.error("Error fetching equipment data:", error);
      toast.error("Error fetching equipment data.");
    }
  };

  const handleOpenDeleteDialog = (supplyId: any) => {
    setSelectedSupply(supplies.find((supply) => supply.id === supplyId));
    setOpenDeleteDialog(true);
  };

  const handleOpenViewDetails = (supplyId: any) => {
    // console.log("Opening details for supply:", supplyId);
    const supply = supplies.find((s) => s.id === supplyId);
    setSelectedSupply(supply);
    setViewDetailsOpen(true);
  };

  const handleCloseViewDetails = () => {
    setViewDetailsOpen(false);
  };

  // Calculate the total number of pages
  const pageCount = Math.ceil(supplies.length / pageSize);

  // Handle change page
  const handleChangePage = (event: any, newPage: any) => {
    setCurrentPage(newPage);
  };

  // Handle change page size
  const handleChangePageSize = (event: any) => {
    setPageSize(event.target.value);
    setCurrentPage(1); // Reset to first page with new page size
  };

  const dataFetched: any = categoriesData;
  useEffect(() => {
    if (dataFetched) {
      if (Array.isArray(categoriesData)) {
        setCategories(categoriesData);
      } else {
        setError("Data is not in expected format");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetched]);

  const handleAddSupply = (newSupply: any) => {
    setSupplies((prevSupplies) => [...prevSupplies, newSupply]);
  };

  //-------------Filter and search------------------
  const filteredSupplies = supplies.filter((supply) => {
    return (
      supply &&
      supply.name &&
      supply.name.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchText(event.target.value);
  };

  //totalValue
  useEffect(() => {
    // Calculate the total value based on the filtered supplies
    const calculateTotalValue = () => {
      const total = filteredSupplies.reduce(
        (acc, supply) => acc + supply.quantity * supply.price,
        0
      );
      return total;
    };

    const newTotalValue = calculateTotalValue();
    setTotalValue(newTotalValue); // Now this will work because setTotalValue is defined
  }, [filteredSupplies]);

  useEffect(() => {
    if (filteredData && Array.isArray(filteredData.supplies)) {
      setSupplies(filteredData.supplies);
    }
  }, [filteredData]);

  //------------------------------------------------

  // Get the supplies for the current page
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const currentSupplies = filteredSupplies.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  // total Items
  const totalItems = supplies.length;

  const totalQuantity = supplies.reduce(
    (acc, supply) => acc + supply.quantity,
    0
  );

  //------------------------------------------------

  // if (fetchError) {
  //   return (
  //     <Box>
  //       <Typography color="error">Error: {fetchError}</Typography>
  //     </Box>
  //   );
  // }
  // if (filteredError) {
  //   return (
  //     <Box>
  //       <Typography color="error">Error: {filteredError}</Typography>
  //     </Box>
  //   );
  // }

  // if (isLoading) {
  //   return (
  //     <Box>
  //       <Typography>Loading...</Typography>
  //     </Box>
  //   );
  // }

  // console.log('================= New Supply===================');
  // console.log(supplies);
  // console.log('====================================');

  const formatNumber = (number: any) => {
    return new Intl.NumberFormat().format(number);
  };

  return (
    <Box sx={{ p: 2 }}>
      <>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
            // padding: "16px",
            gap: "16px",
          }}
        >
          <TextField
            label="Search"
            variant="outlined"
            value={searchText}
            onChange={handleSearchTextChange}
            sx={{ flexGrow: 1, maxWidth: "320px", minWidth: "180px" }}
          />
          <ButtonAdd onClick={handleOpenAddDialog} name=" New Supply" />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            mb: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 2,
              minWidth: "300px",
            }}
          >
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="category-select-label">Category</InputLabel>
                <Select
                  labelId="category-select-label"
                  id="category-select"
                  value={selectedCategory}
                  label="Category"
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  <MenuItem value="All">
                    <em>All Categories</em>
                  </MenuItem>
                  {categories.map((category) => (
                    <MenuItem key={category.id} value={category.name}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="tag-select-label">Tag</InputLabel>
                <Select
                  labelId="tag-select-label"
                  id="tag-select"
                  value={selectedTag}
                  label="Tag"
                  onChange={(e) => setSelectedTag(e.target.value)}
                >
                  <MenuItem value="All">
                    <em>All Tags</em>
                  </MenuItem>
                  {tags.map((tag) => (
                    <MenuItem key={tag.id} value={tag.name}>
                      {tag.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="warehouse-select-label">Warehouse</InputLabel>
                <Select
                  labelId="warehouse-select-label"
                  id="warehouse-select"
                  value={selectedWarehouse}
                  label="Warehouse"
                  onChange={(e) => setSelectedWarehouse(e.target.value)}
                >
                  <MenuItem value="All">
                    <em>All Warehouses</em>
                  </MenuItem>
                  {warehouses.map((warehouse) => (
                    <MenuItem key={warehouse.id} value={warehouse.name}>
                      {warehouse.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>

          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              minWidth: "250px", // Adjust the minimum width as needed
            }}
          >
            <Typography sx={{ minWidth: "100px", textAlign: "right" }}>
              Items: {totalItems}
            </Typography>
            <Typography sx={{ minWidth: "120px", textAlign: "right" }}>
              Quantity: {totalQuantity}
            </Typography>
            <Typography sx={{ minWidth: "120px", textAlign: "right" }}>
              Total Value: ${totalValue}
            </Typography>
          </Box> */}

          <Grid item xs={12} sm={6} md={3}>
            <Card elevation={3} style={{ padding: 10, minHeight: "50px" }}>
              <Typography variant="h5">Total Items</Typography>
              <Typography variant="h4">{formatNumber(totalItems)}</Typography>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card elevation={3} style={{ padding: 10, minHeight: "50px" }}>
              <Typography variant="h5">Total Quantity</Typography>
              <Typography variant="h4">
                {formatNumber(totalQuantity)}
              </Typography>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card elevation={3} style={{ padding: 10, minHeight: "50px" }}>
              <Typography variant="h5">Total Value</Typography>
              <Typography variant="h4">
                ${formatNumber(totalValue.toFixed(2))}
              </Typography>
            </Card>
          </Grid>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              mb: 2,
              ml: 2,
            }}
          >
            <Typography>Page size:</Typography>
            <Select
              value={pageSize}
              onChange={handleChangePageSize}
              displayEmpty
              inputProps={{ "aria-label": "Page size" }}
              sx={{ m: 1, minWidth: 80 }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </Box>
        </Box>

        <Grid
          container
          spacing={2}
          sx={{
            minWidth: "73vw",
            "& > .MuiGrid-item": {
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              minWidth: "300px",
            },
          }}
        >
          {/* {filteredSupplies.map((supply) => ( */}
          {currentSupplies.map((supply) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={supply.id}>
              <Card
                sx={{
                  bgcolor: (theme) => theme.palette.custom?.card,
                  // padding: "20px",
                }}
                // p={3}
              >
                <CardContent>
                  <CardMedia
                    component="img"
                    height="140"
                    image={
                      supply.images && supply.images.length > 0
                        ? supply.images[0]?.secure_url
                        : "https://ipt.images.tshiftcdn.com/200450/x/0/iceland-photo-tours37.jpg?auto=format%2Ccompress&crop=faces%2Cedges%2Ccenter&bg=%23fff&fit=crop&q=32&h=800&dpr=1"
                    }
                    alt={supply.name}
                    sx={{
                      objectFit: "contain",
                      backgroundColor: "#f0f0f0",
                      // paddingBottom: "20px",
                    }}
                  />
                  <Typography gutterBottom variant="h5" component="div" pt={2}>
                    {supply.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {supply.description}
                  </Typography>
                  {/* <Typography variant="body1">
                    {supply.quantity} Units | ${supply.price}
                  </Typography> */}

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    mt={2}
                    p={1}
                    pl={0}
                    gap={1}
                  >
                    <Box
                      textAlign="center"
                      flex={1}
                      sx={{
                        // backgroundColor: "#f0f0f0",
                        border: "1px solid #d1d1d1",
                        borderRadius: 1,
                        padding: "8px",
                      }}
                    >
                      <Typography variant="caption" color="text.secondary">
                        QTY
                      </Typography>
                      <Typography variant="body1" fontWeight="bold">
                        {supply.quantity || 0}
                      </Typography>
                    </Box>
                    <Box
                      textAlign="center"
                      flex={1}
                      sx={{
                        // backgroundColor: "#f0f0f0",
                        border: "1px solid #d1d1d1",
                        borderRadius: 1,
                        padding: "8px",
                      }}
                    >
                      <Typography variant="caption" color="text.secondary">
                        Value Per
                      </Typography>
                      <Typography variant="body1" fontWeight="bold">
                        ${Number(supply.price || 0).toFixed(2)}
                      </Typography>
                    </Box>
                    <Box
                      textAlign="center"
                      flex={1}
                      sx={{
                        // backgroundColor: "#f0f0f0",
                        border: "1px solid #d1d1d1",
                        borderRadius: 1,
                        padding: "8px",
                      }}
                    >
                      <Typography variant="caption" color="text.secondary">
                        Total Value
                      </Typography>
                      <Typography variant="body1" fontWeight="bold">
                        $
                        {formatNumber(
                          (
                            Number(supply.quantity || 0) *
                            Number(supply.price || 0)
                          ).toFixed(2)
                        )}
                      </Typography>
                    </Box>
                  </Box>

                  {supply.warehouse ? (
                    <Typography variant="caption" display="block" gutterBottom>
                      Warehouse: {supply.warehouse.name}
                    </Typography>
                  ) : (
                    <Typography variant="caption" display="block" gutterBottom>
                      No warehouse specified
                    </Typography>
                  )}
                </CardContent>

                <CardActions>
                  <IconButton onClick={() => handleOpenViewDetails(supply.id)}>
                    <VisibilityIcon />
                  </IconButton>

                  <IconButton onClick={() => handleOpenEditSupply(supply.id)}>
                    <EditIcon />
                  </IconButton>

                  <IconButton onClick={() => handleOpenDeleteDialog(supply.id)}>
                    <DeleteIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
          {/* ))} */}
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={handleChangePage}
            color="primary"
          />
        </Box>
      </>

      {openDeleteDialog && selectedSupply && (
        <ConfirmDelete
          itemName={selectedSupply.name}
          endpoint={`supplies/${selectedSupply.id}`}
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          onDeleted={() => {
            setSupplies(
              supplies.filter((supply) => supply.id !== selectedSupply.id)
            );
            setOpenDeleteDialog(false);
          }}
        />
      )}

      <Dialog open={viewDetailsOpen} onClose={handleCloseViewDetails}>
        {selectedSupply && (
          <SupplyDetails
            supply={selectedSupply as any}
            open={viewDetailsOpen}
            handleClose={handleCloseViewDetails}
          />
        )}
      </Dialog>

      <Dialog open={openEditSupply} onClose={() => setOpenEditSupply(false)}>
        {selectedSupply && (
          <EditSupply
            key={selectedSupply.id}
            handleClose={() => setOpenEditSupply(false)}
            supply={selectedSupply}
            setSupplies={setSupplies}
            supplies={supplies}
          />
        )}
      </Dialog>

      <Dialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <AddSupply
          handleClose={() => setOpenAddDialog(false)}
          onAddSupply={handleAddSupply}
        />
      </Dialog>
    </Box>
  );
}
